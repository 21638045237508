<template>
    <div id="ItemContainer" class="margintop">
        <div id="TopbarContainer">
            <h1 id="Title" class="px-1">Equipes do Projeto CLEG</h1>
        </div>
        <div id="ItemDisplay">
            <div v-for="equipe in equipes" :key="equipe.nomeEquipe">
                <hr class="mt-4" />
                <div class="mt-4">
                    <h2 class="d-flex align-items-center">
                        {{ equipe.nomeEquipe }}
                        <v-btn small outlined icon
                            @click="show !== equipe.nomeEquipe ? show = equipe.nomeEquipe : show = ''" class="mx-4">
                            <v-icon v-text="show === equipe.nomeEquipe ? ic : icon" />
                        </v-btn>
                    </h2>
                </div>
                    <div v-if="show === equipe.nomeEquipe" class="mt-4 px-2">
                        <div class="mx-4 px-4" id="TextoEquipes" v-html="equipe.texto"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mdiPlus } from '@mdi/js';
import { mdiMinus } from '@mdi/js';

export default {
    name: "Equipe",
    data: () => {
        return ({
            icon: mdiPlus,
            ic: mdiMinus,
            show: '',
            equipes: [],
        })
    },
    methods: {
        getEquipes: async function () {
                await this.$http.get("/SaibaMais/GetAllEquipes")
                        .then(result => {
                              this.equipes = result.data;
                              console.log(this.equipes);
                        })
                        .catch(error => {
                              this.$swal("Erro", error.response.data, "error");
                        });
            },
    },
    mounted: function () {
        this.getEquipes();
    }
}
</script>

<style>
@import "../About.css";
</style>